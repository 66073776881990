@import "variables";
@import "mixins";

.dreamerButton {
  width: 50%;
  background-color: $dreamerButtonColor;
  border: rgb(1, 1, 54);
  border-radius: 8px;
  margin: 0.5rem 0;
}
.dreamerButton:hover {
  background-color: $dreamerButtonColorHover;
  border: #143015;
}

.transformerButton {
  width: 50%;
  background-color: $transformerButtonColor;
  border: rgb(1, 1, 54);
  border-radius: 8px;
  margin: 1rem auto;
}
.transformerButton:hover {
  background-color: $transformerButtonColorHover;
}
.transformerButton:active {
  background-color: $transformerButtonColorActive;
}

.accordionButton {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: $blueText;
  border: rgb(1, 1, 54);
  border-radius: 8px;
  margin: 1rem auto;
}
.transformerButton:hover {
  background-color: $blueTextHover;
}
.transformerButton:active {
  background-color: $blueTextHover;
}
