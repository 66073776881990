.landingWrapper {
  padding: 0 !important;
  margin: 0 auto !important;
  overflow-x: hidden;
}

//GENERAL

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
