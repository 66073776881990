@import "variables";
@import "mixins";
html {
  scroll-behavior: smooth;
  padding: 0px !important;

}




.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  background-color: #082040;

  z-index: 10;

  .generalContainer {
    margin-top: 200px;
    .infoGeneralContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      .infoDetailContainer {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
          user-select: none;
        }
        .linksContainer {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          height: 200px;
          justify-content: space-around;
          align-items: center;
          .links {
            color: white;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
    .rightsContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 10px;

      .rights {
        color: white;
      }
    }
  }
}
