.image-container {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 19px;

  img {
    border-radius: 14px;
  }
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #0659fd, #79d0f1);
  border-image-slice: 1;
  border-radius: 19px;
}

.border-gradient {
  border: 10px solid transparent;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 19px;
  border-image-source: linear-gradient(to left, #0659fd, #79d0f1);
}
