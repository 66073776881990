@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';

@mixin mobile() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin small-mobile($max-width: 480px) {
  @media (max-width: #{$max-width}) {
    @content;
  }
}
@mixin small() {
  @media (min-width: 769px) and (max-width: 991px) {
    @content;
  }
}
@mixin maxLarge() {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin maxSmall() {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin medium() {
  @media (min-width: 991px) {
    @content;
  }
}
@mixin large() {
  @media (min-width: 1199px) {
    @content;
  }
}
@mixin XL() {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin XXL() {
  @media (min-width: 1600px) {
    @content;
  }
}
@mixin XXXL() {
  @media (min-width: 1920px) {
    @content;
  }
}
@mixin PLUSULTRA() {
  @media (min-width: 2200px) {
    @content;
  }
}
@mixin belowXL() {
  @media (max-width: 1440px) {
    @content;
  }
}
