.control-button:hover {
  cursor: pointer;
}

.alice-carousel__next-btn {
  //width: 40px;
  display: inline-flex;
  justify-content: start;
  width: 8%;
  height: 55px;

  @media screen and (max-width: 1200px) {
    width: 50%;
  }
}

.alice-carousel__prev-btn {
  //width: 40px;
  display: inline-flex;
  justify-content: end;
  width: 92%;
  height: 55px;

  @media screen and (max-width: 1200px) {
    width: 50%;
  }
}
