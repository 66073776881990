@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap");

@import "../src/app/assets/scss/theme.scss";

html {
  overflow-y: auto;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: hidden !important;
  font-family: "Omnes";
  src: local("OmnesRegular"),
    url("./app/assets/fonts/omnes-regular.otf") format("truetype");
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 0 90px;
  margin-bottom: 130px;
}

//FONTS

@font-face {
  font-family: "Omnes";
  src: local("OmnesRegular"),
    url("./app/assets/fonts/omnes-regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Omnes";
  src: local("OmnesRegular"),
    url("./app/assets/fonts/omnes-bold-italic.otf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Omnes";
  src: local("omnes-semibold"),
    url("./app/assets/fonts/omnes-semibold.otf") format("truetype");
  font-weight: 600;
}

h1,
h2,
h3 {
  font-weight: bold;
}

button .MuiButton-contained {
  display: inline-block;
  min-width: 98px !important;
}
.drag-n-drop {
  height: 125px;
  width: 75%;
  border-radius: 24px;
  border: 1px solid $theme-main;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.scrollable-element::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #886cbd;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #864bf1;
}

html {
  scroll-behavior: smooth;
}