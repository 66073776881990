@import "variables";
@import "mixins";
html {
  scroll-behavior: smooth;
  padding: 0px !important;
}

.backgroundMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @include mobile() {
    min-height: 100vh;
  }
}
.pageOneContainer {
  width: 100%;
  overflow-y: hidden;
  //overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;


  background-image: url("../img/main-bg.png");
  background-position: top;
  background-size: 100% 100%;

  background-repeat: no-repeat;
  height: 130vh;

  padding-bottom: 3.125em;


  @include mobile() {

    background: linear-gradient(rgba(134, 124, 194, 0.4), rgba(26, 10, 119, 0.4)),
      url("../img/main-left.png");
    background-size: cover;
    background-position: left;
    height: 110vh;
    
  }
  @include small-mobile() {

    background: linear-gradient(rgba(134, 124, 194, 0.4), rgba(26, 10, 119, 0.4)),
      url("../img/main-left.png");
    background-size: cover;
    background-position: left;
    height: 100%;
    min-height: 100vh;
  }

  @include small() {

    background: linear-gradient(rgba(134, 124, 194, 0.4), rgba(26, 10, 119, 0.4)),
      url("../img/main-left.png");
    background-size: cover;
    background-position: left;
    height: 110vh;
  }
  @include medium() {
    max-height: 75vh;
    background-size: cover;
  }
  @include large() {
    max-height: 75vh;
    background-size: cover;
  }
  @include XL() {
    max-height:100vh;
    background-size: cover;
  }
  @include mobile() {
  }
  @include maxLarge() {
    background-size: cover;
    max-height: 70vh;
  }
  @include XL() {
  }
  .shapeBg {
    position: sticky;

    width: 100%;
    height: auto;
    margin-top: -16em;
    @include maxLarge() {
      display: none;
    }

    // @include small() {
    //   bottom: 115px;
    // }
    // @include belowXL() {
    //   margin-top: 0em;
    // }
    // @include XL() {
    //   bottom: -290px;
    // }
    // @include XXL() {
    //   bottom: -300px;
    // }
    // @include XXXL() {
    //   bottom: -345px;
    // }
    // @include PLUSULTRA() {
    //   bottom: -25px;
    // }
  }

  .headerContent {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 3em auto 0 auto;
    flex-wrap: wrap;
    @include maxLarge() {
      margin: 0 auto;
    }

    @include mobile() {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  // background-color: #082040;
  background-image: url("../img/footerBackground.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;

  .generalContainer {
    margin-top: 200px;
    .infoGeneralContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      .infoDetailContainer {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
          user-select: none;
        }
        .linksContainer {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          height: 200px;
          justify-content: space-around;
          align-items: center;
          .links {
            color: white;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
    .rightsContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 10px;

      .rights {
        color: white;
      }
    }
  }
}
