//colors
$theme-main: #e00060;
$dreamer: #2eccac;
$transformer: #223f99;
$trBG: #39c2ff;
$drBG: #2642d3;
$blue: #223F99;
$theme-main-dark: #193453;
$theme-main-gray: #e6dacd;
$theme-main-highlight: #f8008d;
$text-dark: #181a18;
$text-grey: #454857;
$gold: #ffef95;
$dreamerButtonColor: #e1c3e7;
$dreamerButtonColorHover: #ecc1f4;
$dreamerButtonColorActive: #c891d2;
$transformerButtonColor: #5DE8CD;
$transformerButtonColorHover: #86ecd8;
$transformerButtonColorActive: #46ceb3;
$blueText: #223F99;
$blueTextHover: #4a68c4;
$blueTextActive: #4a68c4;

// $theme-main: #e00060;
// $theme-main-dark: #444357;
// $theme-main-gray: #e6dacd;
// $theme-main-highlight: #f8008d;
// $text-dark: #181a18;
// $text-grey: #454857;
// $gold: #ffef95;

$primary: #0061fe;
$secondary: #f7f8fa;
$danger: #df0404;
$body-bg: #F9F9FC;
$light: #ffffff;

$btn-border-radius: 0;
$input-border-radius: 0;

$enable-negative-margins: true;

// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";
